import {
  composeBundles,
  createCacheBundle,
  createUrlBundle,
} from "redux-bundler";
import cache from "../utils/cache";

import aboutBundle from "./about-bundle";
import accessRequestBundle from "./access-request-bundle";
import aircraftBundle from "./aircraft-bundle";
import aircraftDocsBundle from "./aircraft-docs-bundle";
import aircraftMaintenanceBundle from "./aircraft-maintenance-bundle";
import aircraftMissionsBundle from "./aircraft-missions-bundle";
import aircraftReportBundle from "./aircraft-report-bundle";
import allEquipmentBundle from "./all-equipment-bundle";
import apiBundle from "./api-bundle";
import appRolesBundle from "./app-roles-bundle";
import approvalRolesBundle from "./approval-roles-bundle";
import approvalRolesPersonnelBundle from "./approval-roles-personnel-bundle";

import authBundle from "./auth-bundle";
import authDevBundle from "./auth-dev-bundle";
import backlogBundle from "./backlog-bundle";
import batteriesBundle from "./batteries-bundle";
import batteriesDocsBundle from "./batteries-docs-bundle";
import batteriesMissionsBundle from "./batteries-missions-bundle";
import batteriesPlatformsBundle from "./batteries-platforms-bundle";
import batteriesReportBundle from "./batteries-report-bundle";
import urlBasePath from "./create-url-base-bundle";
import forumUserPermissionsBundle from "./forum-user-permissions-bundle";
import browserBundle from "./browser-bundle";
import coaDocsBundle from "./coa-docs-bundle";
import dashboardCountsBundle from "./dashboard-counts-bundle";
import domainsCompositeBundle from "./domains-composite-bundle";
import domainsEditableBundle from "./domains-editable-bundle";
import efTwentyBundle from "./ef-twenty-bundle";
import efTwentyTwoBundle from "./ef-twentytwo-bundle";
import equipmentRequestBundle from "./equipment-request-bundle";
import equipmentRequestCountsBundle from "./equipment-request-counts-bundle";
import equipmentRequestDocsBundle from "./equipment-request-docs-bundle";
import faqsBundle from "./faqs-bundle";
import faqsRatingsBundle from "./faqs-ratings-bundle";
import fileIoBundle from "./file-io-bundle";
import flightsTabRouterBundle from "../modules/missions/details/flights-tab/flights-tab-routes-bundle";
import gcsBundle from "./gcs-bundle";
import gcsDocsBundle from "./gcs-docs-bundle";
import gcsMissionsBundle from "./gcs-missions-bundle";
import gcsPlatformsBundle from "./gcs-platforms-bundle";
import gcsReportBundle from "./gcs-report-bundle";
import gcsSoftwareBundle from "./gcs-software-bundle";
import helpBundle from "./help-bundle";
import jobsBundle from "./jobs-bundle";
import jobsCommentsBundle from "./jobs-comments-bundle";
import jobsDocsBundle from "./jobs-docs-bundle";
import jobsViewsBundle from "./jobs-views-bundle";
import healthAssessmentBundle from "./health-assessment-bundle";
import libraryBundle from "./library-bundle";
import mapAdvisoriesBundle from "./map-advisories-bundle";
import mapAoiBundle from "./map-aoi-bundle";
import mapAirspaceBundle from "./map-airspace-bundle";
import mapBasemapBundle from "./map-basemap-bundle";
import mapBundle from "./map-bundle";
import mapHazardsBundle from "./map-hazards-bundle";
import mapIdentifyBundle from "./map-identify-bundle";
import mapInteractionsBundle from "./map-interactions-bundle";
import mapLzBundle from "./map-lz-bundle";
import mapKmlBundle from "./map-kml-bundle";
import mapWeatherBundle from "./map-weather-bundle";
import messagesBundle from "./messages-bundle";
import messagesCommentsBundle from "./messages-comments-bundle";
import messagesRatingsBundle from "./messages-ratings-bundle";
import missionsAircraftBundle from "./missions-aircraft-bundle";
import missionsAdjudicationsBundle from "./missions-adjudications-bundle";
import missionsAlertBundle from "./missions-alert-bundle";
import missionsAoiBundle from "./missions-aoi-bundle";
import missionsApprovalBundle from "./missions-approval-bundle";
import missionsApprovalNotesBundle from "./missions-approval-notes-bundle";
import missionsAreaDataBundle from "./missions-area-data-bundle";
import missionsBatteriesBundle from "./missions-batteries-bundle";
import missionsBundle from "./missions-bundle";
import missionsDailyRawBundle from "./missions-dailyraw-bundle";
import missionsDebriefBundle from "./missions-debrief-bundle";
import missionsDocsBundle from "./missions-docs-bundle";
import missionsFiguresBundle from "./missions-figures-bundle";
import missionsFlightsAircraftBundle from "./missions-flights-aircraft-bundle";
import missionsFlightsBatteriesBundle from "./missions-flights-batteries-bundle";
import missionsFlightsBundle from "./missions-flights-bundle";
import missionsFlightsGcsBundle from "./missions-flights-gcs-bundle";
import missionsFlightsMmsBundle from "./missions-flights-mms-bundle";
import missionsFlightsMishapsBundle from "./missions-flights-mishaps-bundle";
import missionsFlightsPayloadsBundle from "./missions-flights-payloads-bundle";
import missionsFlightsPersonnelBundle from "./missions-flights-personnel-bundle";
import missionsGcsBundle from "./missions-gcs-bundle";
import missionsMmsBundle from "./missions-mms-bundle";
import missionsHelpBundle from "./missions-help-bundle";
import missionsLzBundle from "./missions-lz-bundle";
import missionsPayloadsBundle from "./missions-payloads-bundle";
import missionsPersonnelBundle from "./missions-personnel-bundle";
import missionsRawBundle from "./missions-raw-bundle";
import missionsRawHazardsBundle from "./missions-raw-hazards-bundle";
import missionsReportBundle from "./missions-report-bundle";
import mmsBundle from "./mms-bundle";
import mmsDocsBundle from "./mms-docs-bundle";
import mmsMissionsBundle from "./mms-missions-bundle";
import mmsPlatformsBundle from "./mms-platforms-bundle";
import mmsSoftwareBundle from "./mms-software-bundle";
import modalDialogBundle from "./modal-dialog-bundle";
import nestedUrlBundle from "./nested-url-bundle";
import notesBundle from "./notes-bundle";
import notificationBundle from "./notification-bundle";
import orgRolesBundle from "./org-roles-bundle";
import orgUsersBundle from "./org-users-bundle";
import orgsBundle from "./orgs-bundle";
import payloadsBundle from "./payloads-bundle";
import payloadsDocsBundle from "./payloads-docs-bundle";
import payloadsMissionsBundle from "./payloads-missions-bundle";
import payloadsPlatformsBundle from "./payloads-platforms-bundle";
import payloadsReportBundle from "./payloads-report-bundle";
import personnelBundle from "./personnel-bundle";
import personnelDocsBundle from "./personnel-docs-bundle";
import personnelMissionsBundle from "./personnel-missions-bundle";
import personnelReportBundle from "./personnel-report-bundle";
import personnelRolesBundle from "./personnel-roles-bundle";
import personnelTrainingBundle from "./personnel-training-bundle";
import platformsBundle from "./platforms-bundle";
import platformsDocsBundle from "./platforms-docs-bundle";
import platformsGcsBundle from "./platforms-gcs-bundle";
import printBundle from "./print-bundle";
import profileBundle from "./profile-bundle";
import releaseNotesBundle from "./release-notes-bundle";
import routeBundle from "./route-bundle";
import trainingBundle from "./training-bundle";
import transfersBundle from "./transfers-bundle";
import transfersDocsBundle from "./transfers-docs-bundle";
import transfers4900DocsBundle from "./transfers-4900-docs-bundle";
import transfersEquipmentBundle from "./transfers-equipment-bundle";
import transfersStatusBundle from "./transfers-status-bundle";
import uiBundle from "./ui-bundle";
import tabsBundle from "./tabs-bundle";
import atpmDashboardBundle from "./atpm-dashboard-bundle";
import profileDocsBundle from "./profile-docs-bundle";
import atpmTransferBundle from "./atpm-transfer-bundle";
import platformsPayloadBundle from "./platforms-payload-bundle";
import atpmControlBundle from "./atpm-control-bundle";
import profileActiveBundle from "./profile-active-bundle";
import efTwentyApprovalBundle from "./ef-twenty-approval-bundle";
import downtimeBundle from "./downtime-bundle";
import profileAlertBundle from "./profile-alert-bundle";
import readFileBundle from "./read-file-bundle";
import aircraftConfigurationBundle from "./aircraft-configuration-bundle";
import missionsConfigurationsBundle from "./missions-configurations-bundle";
import dashboardAoiBundle from "./dashboard-aoi-bundle";
import coaApprovalDocsBundle from "./coa-approval-docs-bundle";

import gradeSlipBundle from "./grade-slip-bundle";
import gradeSlipEntryBundle from "./grade-slip-entry-bundle";
import healthAssessmentApprovalBundle from "./health-assessment-approval-bundle";


export default composeBundles(
  createCacheBundle({
    cacheFn: cache.set,
  }),
  aboutBundle,
  accessRequestBundle,
  aircraftBundle,
  aircraftDocsBundle,
  aircraftMaintenanceBundle,
  aircraftMissionsBundle,
  aircraftConfigurationBundle,
  aircraftReportBundle,
  allEquipmentBundle,
  apiBundle,
  appRolesBundle,
  approvalRolesBundle,
  approvalRolesPersonnelBundle,
  atpmControlBundle,
  atpmDashboardBundle,
  atpmTransferBundle,
  process.env.NODE_ENV === "development" ? authDevBundle : authBundle,
  backlogBundle,
  batteriesBundle,
  batteriesDocsBundle,
  batteriesMissionsBundle,
  batteriesPlatformsBundle,
  batteriesReportBundle,
  browserBundle,
  createUrlBundle(),
  urlBasePath({
    base: import.meta.env.VITE_URL_BASE_PATH
      ? import.meta.env.VITE_URL_BASE_PATH
      : "",
  }),
  coaApprovalDocsBundle,
  coaDocsBundle,
  dashboardAoiBundle,
  dashboardCountsBundle,
  domainsCompositeBundle,
  domainsEditableBundle,
  efTwentyApprovalBundle,
  efTwentyBundle,
  efTwentyTwoBundle,
  equipmentRequestBundle,
  equipmentRequestCountsBundle,
  equipmentRequestDocsBundle,
  downtimeBundle,
  faqsBundle,
  faqsRatingsBundle,
  fileIoBundle,
  flightsTabRouterBundle,
  gcsBundle,
  gcsDocsBundle,
  gcsMissionsBundle,
  gcsPlatformsBundle,
  gcsReportBundle,
  gcsSoftwareBundle,
  gradeSlipBundle,
  gradeSlipEntryBundle,
  healthAssessmentBundle,
  healthAssessmentApprovalBundle,
  helpBundle,
  forumUserPermissionsBundle,
  jobsBundle,
  jobsCommentsBundle,
  jobsDocsBundle,
  jobsViewsBundle,
  libraryBundle,
  mapAdvisoriesBundle,
  mapAoiBundle,
  mapAirspaceBundle,
  mapBasemapBundle,
  mapBundle,
  mapHazardsBundle,
  mapIdentifyBundle,
  mapInteractionsBundle,
  mapLzBundle,
  mapKmlBundle,
  mapWeatherBundle,
  messagesBundle,
  messagesCommentsBundle,
  messagesRatingsBundle,
  missionsAircraftBundle,
  missionsAdjudicationsBundle,
  missionsAlertBundle,
  missionsAoiBundle,
  missionsApprovalBundle,
  missionsApprovalNotesBundle,
  missionsAreaDataBundle,
  missionsBatteriesBundle,
  missionsBundle,
  missionsConfigurationsBundle,
  missionsDailyRawBundle,
  missionsDebriefBundle,
  missionsDocsBundle,
  missionsFiguresBundle,
  missionsFlightsAircraftBundle,
  missionsFlightsBatteriesBundle,
  missionsFlightsBundle,
  missionsFlightsGcsBundle,
  missionsFlightsMmsBundle,
  missionsFlightsMishapsBundle,
  missionsFlightsPayloadsBundle,
  missionsFlightsPersonnelBundle,
  missionsGcsBundle,
  missionsMmsBundle,
  missionsHelpBundle,
  missionsLzBundle,
  missionsPayloadsBundle,
  missionsPersonnelBundle,
  missionsRawBundle,
  missionsRawHazardsBundle,
  missionsReportBundle,
  mmsBundle,
  mmsDocsBundle,
  mmsMissionsBundle,
  mmsPlatformsBundle,
  mmsSoftwareBundle,
  modalDialogBundle,
  nestedUrlBundle,
  notesBundle,
  notificationBundle,
  orgRolesBundle,
  orgUsersBundle,
  orgsBundle,
  payloadsBundle,
  payloadsDocsBundle,
  payloadsMissionsBundle,
  payloadsPlatformsBundle,
  payloadsReportBundle,
  personnelBundle,
  personnelDocsBundle,
  personnelMissionsBundle,
  personnelReportBundle,
  personnelRolesBundle,
  personnelTrainingBundle,
  platformsBundle,
  platformsDocsBundle,
  platformsGcsBundle,
  platformsPayloadBundle,
  printBundle,
  profileActiveBundle,
  profileAlertBundle,
  profileBundle,
  profileDocsBundle,
  readFileBundle,
  releaseNotesBundle,
  routeBundle,
  tabsBundle,
  trainingBundle,
  transfersBundle,
  transfersDocsBundle,
  transfers4900DocsBundle,
  transfersEquipmentBundle,
  transfersStatusBundle,
  uiBundle
);
